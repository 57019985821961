
.card {
    text-align: center;
    font-size: 1.4rem;
    color: #fff;
    padding: 28px 18px;
    overflow: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        background-image: linear-gradient(90deg, #a3a4a8 0%, #ededed 30%, #a3a4a8 56%);
        border-radius: 44px;
        z-index: -2;
    }
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(/images/background.jpg);
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 36px;
        z-index: -1;
    }
    &__op {
        color: #c0afc7;
        letter-spacing: 0.28rem !important;
        margin-bottom: 24px;
        font-size: 1.4rem;
    }
    &__tit {
        font-size: 4.8rem;
        margin-bottom: 30px;
        white-space: nowrap;
    }
    &__list {
        text-align: left;
        flex-direction: column;
        line-height: 1.2;
        .info {
            word-break: keep-all;
        }
        .list-tit {
            display: inline-block;
        }
    }
    &__txt {
        line-height: 1.5;
        display: inline-block;
        margin: 30px 0 8px;
    }
    &__ci {
        margin: 0 auto;
    }
    &__sheet {
        border-radius: 8px;
        color: #fff;
        padding: 8px;
        margin-bottom: 30px;
    }
}

.font-weight-bold {
    font-weight: 700;
}

@media (min-width: 768px) {
    .card {
        max-width: 300px;
    }
}
